<template>
  <div class="row justify-content-center">
    <div class="col-sm-12">
      <form @submit.prevent="createAssistant">
        <base-card header="bg-soft-danger text-light" footer="bg-e0">
          <template #header>إنشاء مساعد جديد</template>
          <template #body>
            <div class="row">
              <div class="col-md-12">
                <label>الإسم الرباعي</label>
                <form-input id="name" placeholder="الإسم الرباعي"></form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>البريد الإلكتروني</label>
                <form-input id="email" placeholder="البريد الإلكتروني"></form-input>
              </div>
              <div class="col-md-6">
                <label>
                  <abbr title="يستخدم إسم المستخدم للدخول الى حساب المستخدم لاحقاً">إسم المستخدم</abbr>
                </label>
                <form-input id="username" placeholder="إسم المستخدم"></form-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label>كلمة المرور</label>
                <form-input type="password" id="password" placeholder="كلمة المرور"></form-input>
              </div>
              <div class="col-md-6">
                <label>تأكيد كلمة المرور</label>
                <form-input type="password" id="password_confirmation" placeholder="تأكيد كلمة المرور"></form-input>
              </div>
            </div>
            <hr>
            <p class="text-danger text-left font-weight-bolder">
              <abbr :title="validMobilePattern">ارقام الهواتف</abbr>
            </p>
            <mobile-inputs></mobile-inputs>
          </template>
          <template #footer>
            <button type="submit" class="btn btn-info nr-shadow">إنشاء</button>
          </template>
        </base-card>
      </form>
    </div>
  </div>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
import FormInput from "@/components/Forms/FormInput";
import MobileInputs from "@/components/Users/Mobile/MobileInputs";

export default {
  components: {MobileInputs, FormInput, BaseCard},
  computed: {
    validMobilePattern() {
      return messages.validMobilePattern;
    },
  },
  methods: {
    async createAssistant(e) {
      const response = await http.send('assistants', new FormData(e.target));
      if (http.responseAccepted(response, 'id'))
        await this.$router.replace({name: 'assistants.edit', params: {user: response.data.id}});
    },
  }
}
</script>